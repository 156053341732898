import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import firebase from "firebase/app";
import "firebase/analytics";

import Footer from './shared/Footer/Footer';
import Menu from './shared/Menu/Menu';
import ScrollToTop from './shared/UiElements/ScrollToTop';
import Work from './work/Work';
import Activities from './activities/Activities';
import Press from './press/Press';
import About from './about/About';
import Main from './main/Main';
import Partners from './partners/Partners';
import Single from './single/Single';
import Roaming from './single/components/Roaming/Roaming';
import Campaign from './campaign/Campaign';
import Books from './books/Books';

const App = (props) => {
  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang === 'es') {
      props.onChangeToSpanish()
    }
    if (lang === 'en') {
      props.onChangeToEnglish()
    }
    if (lang === 'fr') {
      props.onChangeToFrench()
    }
  });

  return <Router>
    <Switch>
      <Route path="/" exact>
        <ScrollToTop>
          <Menu />
          <Main />
        </ScrollToTop>
      </Route>

      <Route path="/proyectos" exact>
        <ScrollToTop>
          <Menu />
          <Work />
        </ScrollToTop>
      </Route>

      <Route path="/proyectos/:workSlug" exact>
        <ScrollToTop>
          <Menu />
          <Single />
        </ScrollToTop>
      </Route>

      <Route path="/proyectos/:workSlug/:roamingSlug" exact>
        <ScrollToTop>
          <Menu />
          <Roaming />
        </ScrollToTop>
      </Route>

      <Route path="/actividades" exact>
        <ScrollToTop>
          <Menu />
          <Activities />
        </ScrollToTop>
      </Route>

      <Route path="/actividades/:slug" exact>
        <ScrollToTop>
          <Menu />
          <Activities />
        </ScrollToTop>
      </Route>

      <Route path="/comunicacion" exact>
        <ScrollToTop>
          <Menu />
          <Press />
        </ScrollToTop>
      </Route>

      <Route path="/quienes" exact>
        <ScrollToTop>
          <Menu />
          <About />
        </ScrollToTop>
      </Route>

      <Route path="/colaboradores" exact>
        <ScrollToTop>
          <Menu />
          <Partners />
        </ScrollToTop>
      </Route>

      <Route path="/campaña" exact>
        <ScrollToTop>
          <Menu />
          <Campaign />
        </ScrollToTop>
      </Route>

      <Route path="/libros" exact>
        <ScrollToTop>
          <Menu />
          <Books />
        </ScrollToTop>
      </Route>

      <Redirect to="/" />
    </Switch>
    <Footer />
  </Router>;
};

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: "AIzaSyCXmJrLNWP7rtLkvDL_RntmgqT74w2foNo",
  authDomain: "rocaviva-1ca61.firebaseapp.com",
  databaseURL: "https://rocaviva-1ca61.firebaseio.com",
  projectId: "rocaviva-1ca61",
  storageBucket: "rocaviva-1ca61.appspot.com",
  messagingSenderId: "557550192739",
  appId: "1:557550192739:web:2af68dc9c9f4d577211056",
  measurementId: "G-XPR1HTTQTV"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const mapStateToProps = state => {
  return {
    lang: state.lang
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onChangeToSpanish: () => dispatch({ type: 'CHANGE_TO_ES' }),
    onChangeToEnglish: () => dispatch({ type: 'CHANGE_TO_EN' }),
    onChangeToFrench: () => dispatch({ type: 'CHANGE_TO_FR' })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
