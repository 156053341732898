import React from "react";
import { Link } from 'react-router-dom';

import './WorkItem.css';

const WorkItem = props => {
    return (
        <Link to={`proyectos/${props.slug}`}>
            <div className="workitem">
                <img src={props.image} alt={props.title} />
                <h2>{props.title}</h2>
            </div>
        </Link>
    );
};
export default WorkItem;