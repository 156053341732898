import React from "react";
import { connect } from 'react-redux';

import MainNews from './components/MainNews/MainNews';
import ActivitiesContent from '../content/Activities.json';
import PressContent from '../content/Press.json';
import NewsContent from '../content/MainNews.json';
import './Main.css';

const MainEventsContent_es = ActivitiesContent.activities_es.concat(PressContent.press_es);
const MainEventsContent_en = ActivitiesContent.activities_en.concat(PressContent.press_en);
const MainEventsContent_fr = ActivitiesContent.activities_fr.concat(PressContent.press_fr);

MainEventsContent_es.sort(function (a, b) {
    var dateA = new Date(b.formated_date), dateB = new Date(a.formated_date);
    return dateA - dateB;
});
MainEventsContent_en.sort(function (a, b) {
    var dateA = new Date(b.formated_date), dateB = new Date(a.formated_date);
    return dateA - dateB;
});
MainEventsContent_fr.sort(function (a, b) {
    var dateA = new Date(b.formated_date), dateB = new Date(a.formated_date);
    return dateA - dateB;
});

const Main = props => {

    if (props.lang === 'es') {
        return (
            <div className="main">
                <MainNews data={NewsContent.news_es} />
            </div>
        );
    }
    if (props.lang === 'en') {
        return (
            <div className="main">
                <MainNews data={NewsContent.news_en} />
            </div>
        );
    }
    if (props.lang === 'fr') {
        return (
            <div className="main">
                <MainNews data={NewsContent.news_fr} />
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        lang: state.lang
    }
}
export default connect(mapStateToProps)(Main);