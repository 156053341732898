import React from "react";
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import Gallery from "../../../../../shared/Gallery/Gallery";
import WorksContent from '../../../../../content/Works.json';
import './RoamingSingle.css';

const RoamingSingle = props => {

    const workSlug = useParams().workSlug;
    const roamingSlug = useParams().roamingSlug;

    const filteredWork_es = WorksContent.works_es.filter(work => work.slug === workSlug);
    const filteredRoaming_es = filteredWork_es[0].roaming.filter(roaming => roaming.slug === roamingSlug);

    const filteredWork_en = WorksContent.works_en.filter(work => work.slug === workSlug);
    const filteredRoaming_en = filteredWork_en[0].roaming.filter(roaming => roaming.slug === roamingSlug);

    const filteredWork_fr = WorksContent.works_fr.filter(work => work.slug === workSlug);
    const filteredRoaming_fr = filteredWork_fr[0].roaming.filter(roaming => roaming.slug === roamingSlug);

    if (props.lang === 'es') {
        return (
            <div className="info_roaming">
                <h1>{props.data.title}</h1>
                <h2>en {filteredRoaming_es[0].title}</h2>

                <p><strong>{filteredRoaming_es[0].place}</strong></p>

                <p><strong>Del {filteredRoaming_es[0].start_date.day} de {filteredRoaming_es[0].start_date.month} de {filteredRoaming_es[0].start_date.year} al {filteredRoaming_es[0].end_date.day} de {filteredRoaming_es[0].end_date.month} de {filteredRoaming_es[0].end_date.year}</strong></p>
                <div dangerouslySetInnerHTML={{ __html: filteredRoaming_es[0].description, }}></div>

                <Gallery images={filteredRoaming_es[0].images}></Gallery>
            </div>
        );
    }
    if (props.lang === 'en') {
        return (
            <div className="info_roaming">
                <h1>{props.data.title}</h1>
                <h2>in {filteredRoaming_en[0].title}</h2>

                <p><strong>{filteredRoaming_en[0].place}</strong></p>

                <p><strong>From {filteredRoaming_en[0].start_date.month} {filteredRoaming_en[0].start_date.day}, {filteredRoaming_en[0].start_date.year} to {filteredRoaming_en[0].end_date.month} {filteredRoaming_en[0].end_date.day}, {filteredRoaming_en[0].end_date.year}</strong></p>
                <div dangerouslySetInnerHTML={{ __html: filteredRoaming_en[0].description, }}></div>

                <Gallery images={filteredRoaming_en[0].images}></Gallery>
            </div>
        );
    }
    if (props.lang === 'fr') {
        return (
            <div className="info_roaming">
                <h1>{props.data.title}</h1>
                <h2>à {filteredRoaming_fr[0].title}</h2>

                <p><strong>{filteredRoaming_fr[0].place}</strong></p>

                <p><strong>Du {filteredRoaming_fr[0].start_date.day} {filteredRoaming_fr[0].start_date.month} {filteredRoaming_fr[0].start_date.year} au {filteredRoaming_fr[0].end_date.day} {filteredRoaming_fr[0].end_date.month} {filteredRoaming_fr[0].end_date.year}</strong></p>
                <div dangerouslySetInnerHTML={{ __html: filteredRoaming_fr[0].description, }}></div>

                <Gallery images={filteredRoaming_fr[0].images}></Gallery>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        lang: state.lang
    }
}
export default connect(mapStateToProps)(RoamingSingle);
