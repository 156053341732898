import React, { useState } from "react";
import { connect } from 'react-redux';

import CampaignList from './components/CampaignList/CampaignList';
import LoadMore from '../shared/LoadMore/LoadMore';
import CampaignContent from '../content/Campaign.json';

let CampaignFinal_es = CampaignContent.campaign_es.sort(function (a, b) {
    var dateA = new Date(b.formated_date), dateB = new Date(a.formated_date);
    return dateA - dateB;
});
let CampaignFinal_en = CampaignContent.campaign_en.sort(function (a, b) {
    var dateA = new Date(b.formated_date), dateB = new Date(a.formated_date);
    return dateA - dateB;
});
let CampaignFinal_fr = CampaignContent.campaign_fr.sort(function (a, b) {
    var dateA = new Date(b.formated_date), dateB = new Date(a.formated_date);
    return dateA - dateB;
});

const Campaign = props => {
    const [number, setNumber] = useState(12);
    const datos_es = CampaignFinal_es.slice(0, number);
    const datos_en = CampaignFinal_en.slice(0, number);
    const datos_fr = CampaignFinal_fr.slice(0, number);

    const [cargarStyle, setCargarStyle] = useState("block");
    const [controlStyle, setControlStyle] = useState("a");

    if (CampaignFinal_es.length <= number && controlStyle === "a") {
        setCargarStyle("none");
        setControlStyle("b");
    }
    function cargarmas() {
        setNumber(number + 12)
    };

    if (props.lang === 'es') {
        return (
            <div>
                <CampaignList data={datos_es} />
                <LoadMore lang="es" cargarmas={cargarmas} cargarStyle={cargarStyle} />
            </div>
        );
    }
    if (props.lang === 'en') {
        return (
            <div>
                <CampaignList data={datos_en} />
                <LoadMore lang="en" cargarmas={cargarmas} cargarStyle={cargarStyle} />
            </div>
        );
    }
    if (props.lang === 'fr') {
        return (
            <div>
                <CampaignList data={datos_fr} />
                <LoadMore lang="fr" cargarmas={cargarmas} cargarStyle={cargarStyle} />
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        lang: state.lang
    }
}
export default connect(mapStateToProps)(Campaign);