import React from "react";
import { connect } from 'react-redux';

import BooksContent from "../content/Books.json";
import BookForm from "./BookForm/BookForm";
import "./Books.css";

const Books = props => {
  if (props.lang === 'es') {
    return (
      <div className="books">
        <div className="books_content">

          <BookForm />

          <div className="books_text">
            <h1>{BooksContent.books_es[0].title}</h1>
            <div className="subvencion">
              <img src="assets/books/logo_ministerio.jpg" alt="logo ministerio de cultura deporte" />
              <p>La edición digital de este libro está subvencionada por el Ministerio de Cultura y Deporte</p>
            </div>
            <div dangerouslySetInnerHTML={{ __html: BooksContent.books_es[0].description, }}></div>
          </div>
        </div>
      </div>
    );
  };
  if (props.lang === 'en') {
    return (
      <div className="books">
        <div className="books_content">

          <BookForm />

          <div className="books_text">
            <h1>{BooksContent.books_en[0].title}</h1>
            <div className="subvencion">
              <img src="assets/books/logo_ministerio.jpg" alt="logo ministerio de cultura deporte" />
              <p>Digital edition of this book has been supported by The Ministry of Culture and Sports</p>
            </div>
            <div dangerouslySetInnerHTML={{ __html: BooksContent.books_en[0].description, }}></div>
          </div>
        </div>
      </div>
    );
  };
  if (props.lang === 'fr') {
    return (
      <div className="books">
        <div className="books_content">

          <BookForm />

          <div className="books_text">
            <h1>{BooksContent.books_fr[0].title}</h1>
            <div className="subvencion">
              <img src="assets/books/logo_ministerio.jpg" alt="logo ministerio de cultura deporte" />
              <p>L'édition digital de ce livre a été soutenue par le ministère de la Culture et des Sports</p>
            </div>
            <div dangerouslySetInnerHTML={{ __html: BooksContent.books_fr[0].description, }}></div>
          </div>
        </div>
      </div>
    );
  };
};

const mapStateToProps = state => {
  return {
    lang: state.lang
  }
}
export default connect(mapStateToProps)(Books);
