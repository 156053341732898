import React from "react";

import './PartnerItem.css';

const PartnerItem = props => {
    return (
        <a href={props.link} target="blank">
            <div className="partneritem">
                <img src={props.image} alt={props.title}></img>
            </div>
        </a>
    );
};
export default PartnerItem;