import React from "react";

import Card from '../../../shared/Card/Card';
import './CampaignList.css';

const CampaignList = props => {
    return (
        <div className="campaign">
            {props.data.map(data => {
                return <Card
                    key={data.id}
                    title={data.title}
                    image={data.image}
                    date={data.date}
                    time={data.time}
                    description={data.description}
                    links={data.links}
                />
            })}
        </div>
    );
};
export default CampaignList;