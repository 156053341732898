import React from "react";

import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <p>© Rocaviva | Rocaviva Ediciones | Rocaviva Eventos</p>
        </footer>
    );
};
export default Footer;