import React, { useState } from "react";
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import './CardToSingle.css';
import LinkItem from "./LinkItem/LinkItem";
import CardModal from "./CardModal/CardModal";

const CardToSingle = props => {

    let slugToCompare = "/actividades/" + props.slug;
    let history = useHistory();

    const [itemStyle, setItemStyle] = useState('none');
    const [finish, setFinish] = useState('a');

    function openItem() {
        setItemStyle("flex");
    }
    function closeItem() {
        setItemStyle("none");
    }

    if (history.location.pathname === slugToCompare && finish === 'a') {
        setItemStyle("flex");
        setFinish("b");
    }

    if (props.lang === 'es') {
        return (
            <React.Fragment>
                <div className="cardts">
                    <Link to={`actividades/${props.slug}`}>
                        <img src={props.image} alt={props.title} onClick={() => openItem()}></img>
                    </Link>
                    <div className="cardts_texto">
                        <h2>{props.title}</h2>
                        <div className="cardts_resumen">
                            <div className="cardts_resumen_date">
                                <p>{props.date.day} de {props.date.month} de {props.date.year}</p>
                                <p>{props.place}</p>
                                <p>{props.time}</p>
                            </div>
                            <p>{props.description}</p>
                        </div>
                        <div className="cardts_enlaces">
                            {props.links.map(links => {
                                return <LinkItem
                                    type={links.type}
                                    link={links.link}
                                />
                            })}
                        </div>
                    </div>
                </div>

                <CardModal
                    key={props.id}
                    title={props.title}
                    image={props.image}
                    date={props.date}
                    place={props.place}
                    time={props.time}
                    description={props.description}
                    links={props.links}
                    itemStyle={itemStyle}
                    closeItem={closeItem}
                />
            </React.Fragment >
        );
    }
    if (props.lang === 'en') {
        return (
            <React.Fragment>
                <div className="cardts">
                    <Link to={`actividades/${props.slug}`}>
                        <img src={props.image} alt={props.title} onClick={() => openItem()}></img>
                    </Link>
                    <div className="cardts_texto">
                        <h2>{props.title}</h2>
                        <div className="cardts_resumen">
                            <div className="cardts_resumen_date">
                                <p>{props.date.day} {props.date.month}, {props.date.year}</p>
                                <p>{props.place}</p>
                                <p>{props.time}</p>
                            </div>
                            <p>{props.description}</p>
                        </div>
                        <div className="cardts_enlaces">
                            {props.links.map(links => {
                                return <LinkItem
                                    type={links.type}
                                    link={links.link}
                                />
                            })}
                        </div>
                    </div>
                </div>

                <CardModal
                    key={props.id}
                    title={props.title}
                    image={props.image}
                    date={props.date}
                    place={props.place}
                    time={props.time}
                    description={props.description}
                    links={props.links}
                    itemStyle={itemStyle}
                    closeItem={closeItem}
                />
            </React.Fragment >
        );
    }
    if (props.lang === 'fr') {
        return (
            <React.Fragment>
                <div className="cardts">
                    <Link to={`actividades/${props.slug}`}>
                        <img src={props.image} alt={props.title} onClick={() => openItem()}></img>
                    </Link>
                    <div className="cardts_texto">
                        <h2>{props.title}</h2>
                        <div className="cardts_resumen">
                            <div className="cardts_resumen_date">
                                <p>{props.date.day} {props.date.month}, {props.date.year}</p>
                                <p>{props.place}</p>
                                <p>{props.time}</p>
                            </div>
                            <p>{props.description}</p>
                        </div>
                        <div className="cardts_enlaces">
                            {props.links.map(links => {
                                return <LinkItem
                                    type={links.type}
                                    link={links.link}
                                />
                            })}
                        </div>
                    </div>
                </div>

                <CardModal
                    key={props.id}
                    title={props.title}
                    image={props.image}
                    date={props.date}
                    place={props.place}
                    time={props.time}
                    description={props.description}
                    links={props.links}
                    itemStyle={itemStyle}
                    closeItem={closeItem}
                />
            </React.Fragment >
        );
    }
};

const mapStateToProps = state => {
    return {
        lang: state.lang
    }
}
export default connect(mapStateToProps)(CardToSingle);