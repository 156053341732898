import React from "react";

import CardToSingle from '../../../shared/CardToSingle/CardToSingle';
import './ActivitiesList.css';

const ActivitiesList = props => {

    return (
        <div className="activities">
            {props.data.map(data => {
                return <CardToSingle
                    key={data.id}
                    title={data.title}
                    slug={data.slug}
                    image={data.image}
                    date={data.date}
                    place={data.place}
                    time={data.time}
                    description={data.description}
                    links={data.links}
                />
            })}
        </div>
    );
};
export default ActivitiesList;