import React from "react";

import PartnerItem from '../PartnerItem/PartnerItem';
import './PartnerList.css';

const PartnerList = props => {
    return (
        <div className="partners">
            {props.data.map(data => {
                return <PartnerItem
                    title={data.title}
                    image={data.image}
                    link={data.link}
                />
            })}
        </div>
    );
};
export default PartnerList;