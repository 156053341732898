import React, { useState } from "react";
import { connect } from 'react-redux';

import PressList from "./components/PressList/PressList";
import PressContent from '../content/Press.json';
import LoadMore from '../shared/LoadMore/LoadMore';

const PressFinal_es = PressContent.press_es.sort(function (a, b) {
    var dateA = new Date(b.formated_date), dateB = new Date(a.formated_date);
    return dateA - dateB;
});
const PressFinal_en = PressContent.press_en.sort(function (a, b) {
    var dateA = new Date(b.formated_date), dateB = new Date(a.formated_date);
    return dateA - dateB;
});
const PressFinal_fr = PressContent.press_fr.sort(function (a, b) {
    var dateA = new Date(b.formated_date), dateB = new Date(a.formated_date);
    return dateA - dateB;
});

const Press = props => {
    const [number, setNumber] = useState(12);
    const datos_es = PressFinal_es.slice(0, number);
    const datos_en = PressFinal_en.slice(0, number);
    const datos_fr = PressFinal_fr.slice(0, number);

    const [cargarStyle, setCargarStyle] = useState("block");
    const [controlStyle, setControlStyle] = useState("a");

    if (PressFinal_es.length <= number && controlStyle === "a") {
        setCargarStyle("none");
        setControlStyle("b");
    }
    function cargarmas() {
        setNumber(number + 12)
    };

    if (props.lang === 'es') {
        return (
            <div>
                <PressList data={datos_es} />
                <LoadMore lang="es" cargarmas={cargarmas} cargarStyle={cargarStyle} />
            </div>
        );
    }
    if (props.lang === 'en') {
        return (
            <div>
                <PressList data={datos_en} />
                <LoadMore lang="en" cargarmas={cargarmas} cargarStyle={cargarStyle} />
            </div>
        );
    }
    if (props.lang === 'fr') {
        return (
            <div>
                <PressList data={datos_fr} />
                <LoadMore lang="fr" cargarmas={cargarmas} cargarStyle={cargarStyle} />
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        lang: state.lang
    }
}
export default connect(mapStateToProps)(Press);