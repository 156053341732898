import React from "react";
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import './RoamingMenu.css';

const RoamingMenu = props => {

    const workSlug = useParams().workSlug;

    if (props.lang === 'es') {
        return (
            <div className="itinerancias">
                <h2>Itinerancias</h2>

                <div className="lista">
                    {props.data.roaming.map(data => {
                        return (
                            <Link to={`${workSlug}/${data.slug}`} key={data.id}>
                                <div className="itinerancia">
                                    <h3>{data.title}</h3>
                                    <p>desde el {data.start_date.day} de {data.start_date.month} de {data.start_date.year}</p>
                                    <p>hasta el {data.end_date.day} de {data.end_date.month} de {data.end_date.year}</p>
                                </div>
                            </Link>
                        );
                    })}
                </div>
            </div>
        );
    }
    if (props.lang === 'en') {
        return (
            <div className="itinerancias">
                <h2>Itinerancias</h2>

                <div className="lista">
                    {props.data.roaming.map(data => {
                        return (
                            <Link to={`${workSlug}/${data.slug}`} key={data.id}>
                                <div className="itinerancia">
                                    <h3>{data.title}</h3>
                                    <p>from {data.start_date.month} {data.start_date.day}, {data.start_date.year}</p>
                                    <p>to {data.end_date.month} {data.end_date.day}, {data.end_date.year}</p>
                                </div>
                            </Link>
                        );
                    })}
                </div>
            </div>
        );
    }
    if (props.lang === 'fr') {
        return (
            <div className="itinerancias">
                <h2>Itinerancias</h2>

                <div className="lista">
                    {props.data.roaming.map(data => {
                        return (
                            <Link to={`${workSlug}/${data.slug}`} key={data.id}>
                                <div className="itinerancia">
                                    <h3>{data.title}</h3>
                                    <p>depuis {data.start_date.day} {data.start_date.month} {data.start_date.year}</p>
                                    <p>jusqu'au {data.end_date.day} {data.end_date.month} {data.end_date.year}</p>
                                </div>
                            </Link>
                        );
                    })}
                </div>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        lang: state.lang
    }
}
export default connect(mapStateToProps)(RoamingMenu);