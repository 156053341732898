import React from 'react';
import { connect } from 'react-redux';

import "./BookForm.css";

const BookForm = props => {
    if (props.lang === 'es') {
        return (
            <div className="bookform">
                <h2>Descarga el libro gratuitamente</h2>
                <img src="/assets/books/book_banner.jpg" alt="" />

                <form action="">
                    <label>Nombre</label>
                    <input type="text" maxlength="20" />

                    <label>Email</label>
                    <input type="email" />

                    <label>Interes en el libro</label>
                    <select name="interes">
                        <option>Personal</option>
                        <option>Profesional</option>
                        <option>Regalo</option>
                    </select>

                    <label>Profesión / Dedicación</label>
                    <input type="text" maxlength="20" />

                    <label>Otros Comentarios</label>
                    <textarea rows="5" cols="60" name="description">
                    </textarea>

                    <a href="/assets/books/MUJERES NOBEL DE CIENCIAS MMXIX.epub" download>Descarga capitulo 1</a>
                    <a href="/assets/books/MUJERES NOBEL DE LITERATURA.epub" download>Descarga capitulo 2</a>
                </form>
            </div>
        );
    };
    if (props.lang === 'en') {
        return (
            <div className="bookform">
                <h2>Download the book for free</h2>
                <img src="/assets/books/book_banner.jpg" alt="" />

                <form action="">
                    <label>Name</label>
                    <input type="text" maxlength="20" />

                    <label>E-mail</label>
                    <input type="email" />

                    <label>Interest for the book</label>
                    <select name="interes">
                        <option>Personal</option>
                        <option>Profesional</option>
                        <option>Gift</option>
                    </select>

                    <label>Profesion / Job</label>
                    <input type="text" maxlength="20" />

                    <label>Other Coments</label>
                    <textarea rows="5" cols="60" name="description">
                    </textarea>

                    <a href="/assets/books/MUJERES NOBEL DE CIENCIAS MMXIX.epub" download>Download chapter 1</a>
                    <a href="/assets/books/MUJERES NOBEL DE LITERATURA.epub" download>Download chapter 2</a>
                </form>
            </div>
        );
    };
    if (props.lang === 'fr') {
        return (
            <div className="bookform">
                <h2>Télécharger Le Livre Gratuitement</h2>
                <img src="/assets/books/book_banner.jpg" alt="" />

                <form action="">
                    <label>Nom</label>
                    <input type="text" maxlength="20" />

                    <label>E-mail</label>
                    <input type="email" />

                    <label>Intérêt pour le livre</label>
                    <select name="interes">
                        <option>Personal</option>
                        <option>Profesional</option>
                        <option>Gift</option>
                    </select>

                    <label>Profession / Emploi</label>
                    <input type="text" maxlength="20" />

                    <label>Autres commentaires</label>
                    <textarea rows="5" cols="60" name="description">
                    </textarea>

                    <a href="/assets/books/MUJERES NOBEL DE CIENCIAS MMXIX.epub" download>Télécharger le chapitre 1</a>
                    <a href="/assets/books/MUJERES NOBEL DE LITERATURA.epub" download>Télécharger le chapitre 2</a>
                </form>
            </div>
        );
    };
};
const mapStateToProps = state => {
    return {
        lang: state.lang
    }
}
export default connect(mapStateToProps)(BookForm);