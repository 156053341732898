import React from "react";

import AboutItem from '../AboutItem/AboutItem';
import './AboutList.css';

const AboutList = props => {
    return (
        <React.Fragment>
            <div className="about_texto">
                <h1>Rocaviva Eventos</h1>
                <p> Rocaviva Eventos es una empresa de divulgación cultural especializada en la difusión de personajes históricos, fundamentalmente femeninos. Fue creada en 2005 y ha realizado exitosas exposiciones con trayectoria internacional entre las que destacan:</p>

                <p><strong>Maria Sklodowska-Curie. Una polaca en París<br></br>

                    Teresa de Jesús. Corazón en España, alma en América<br></br>

                    Mujeres Nobel<br></br>

                    Mujeres Astronautas</strong></p>

                <p>Sus proyectos tienen un carácter global y ofrecen varias <strong>vertientes</strong> para acercar la temática al público desde muy diferentes entornos. Así las exposiciones se complementan con visitas guiadas, conferencias, lecturas dramatizadas, talleres, plantaciones en jardines públicos de árboles o rosales dedicados al personaje, dedicación de laboratorios de investigación o nombrar calles...</p>

                <p>Entre sus <strong>colaboradores</strong> hay especialistas de reconocido prestigio internacional en diferentes ámbitos como el actor Manuel Galiana, los científicos María Vallet, Pierre Joliot y Helène Langevin Joliot -nietos de Marie Curie-, cantantes como el barítono Carlos Álvarez, o periodistas como Antonio Garate.</p>

                <p>Entre sus acciones expositivas cabe destacar <strong>Mujeres Nobel,</strong> en la que han colaborado las propias galardonadas, sus familiares o las fundaciones que custodian sus legados, así como el Museo Nobel de Estocolmo, el Instituto Nobel de Oslo y numerosas embajadas y universidades. Varias mujeres que han sido galardonadas con el Premio Nobel, como Elizabeth Blackburn (Nobel de Medicina 2009), May-Britt Moser (Premio Nobel de Medicina 2014) o Ouided Bouchamaoui (Premio Nobel de la Paz 2015), han acudido a inauguraciones de distintas sedes de la exposición y han participado en actos paralelos.</p>

                <p>Son especialmente relevantes las <strong>lecturas dramatizadas</strong> sobre la vida de diversos personajes con absoluto rigor histórico, ya que las obras están basadas en sus propios escritos: Pierre y Marie Curie. Ellos mismos y Concha Espina. Luz y tiniebla. Las lecturas cuentan con la participación del gran actor Manuel Galiana.</p>

                <p>Durante la pandemia Rocaviva organizó la exitosa <strong>campaña #YoTeAplaudo</strong> en colaboración con el Instituto de Investigación Sanitaria del Hospital Universitario 12 de Octubre de Madrid, en la que 55 personalidades del mundo de la Ciencia, la Cultura, el Arte y el Deporte enviaron mensajes de aliento a los que arriesgaban sus vidas en primera línea.</p>
            </div>

            <div className="contact">
                <h4>Contacto:</h4>
                <a href="mailto:eventos@rocaviva.eu">eventos@rocaviva.eu</a>
                <a href="tel:+34925474267">Tf. 92 547 42 67</a>
                <a href="tel:+34686519372">686 519 372</a>
            </div>

            <div className="aboutlist">
                {props.data.map(data => {
                    return <AboutItem
                        key={data.id}
                        name={data.name}
                        image={data.image}
                        cv={data.cv}
                    />
                })}
            </div>
        </React.Fragment >
    );
};
export default AboutList;