import React from "react";

import './PhotoModal.css';

const PhotoModal = props => {

    let isOpen = props.imagestyle;
    let photo = props.image;
    let description = props.alt;

    return (
        <div className="photomodal" id="photomodal" style={{ display: isOpen }} >
            <div className="image_container">
                <img src={photo} alt={description} />
            </div>

            <div className="photo_ctrl">
                <span className="material-icons" onClick={() => props.moveBackward()}>arrow_back</span>
                <a className="material-icons" href={photo} download>cloud_download</a>
                <span className="material-icons" onClick={() => props.cerrarFoto()}>close</span>
                <span className="material-icons" onClick={() => props.moveForward()}>arrow_forward</span>
            </div>
        </div >
    );
};
export default PhotoModal;