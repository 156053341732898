import React from "react";

import PartnerList from './components/PartnerList/PartnerList';
import PartnersContent from '../content/Partners.json';

const Partners = () => {
    return (
        <PartnerList data={PartnersContent.partners} />
    );
};
export default Partners;