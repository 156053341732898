import React, { useState } from "react";

import './Gallery.css';
import PhotoModal from "./components/PhotoModal/PhotoModal";

const Gallery = props => {

    const [image, setImage] = useState(null);
    const [description, setDescription] = useState(null);
    const [imageStyle, setImageStyle] = useState('none');
    const imagesArray = [];

    props.images.map(image => {
        return (
            imagesArray.push(image)
        );
    });


    function abrirFoto(image, description) {
        setImage(image);
        setDescription(description);
        setImageStyle('flex');
        return;
    };
    const cerrarFoto = () => {
        setImageStyle('none');
        return;
    };

    let photoPosition = imagesArray.findIndex(element => element.image === image);
    let maxNumber = imagesArray.length - 1;

    const moveForward = () => {
        if (photoPosition < maxNumber) {
            return (
                photoPosition++,
                setImage(imagesArray[photoPosition].image),
                setDescription(imagesArray[photoPosition].alt)
            );
        }
        if (photoPosition >= maxNumber) {
            return (
                setImage(imagesArray[0].image),
                setDescription(imagesArray[0].alt)
            );
        }
    };

    const moveBackward = () => {
        if (photoPosition > 0) {
            return (
                photoPosition--,
                setImage(imagesArray[photoPosition].image),
                setDescription(imagesArray[photoPosition].alt)
            );
        }
        if (photoPosition <= 0) {
            return (
                photoPosition = maxNumber,
                setImage(imagesArray[photoPosition].image),
                setDescription(imagesArray[photoPosition].alt)
            );
        }
    };


    return (
        <React.Fragment>
            <div className="galeria">
                {imagesArray.map(image => {
                    return (
                        <div key={image.image}>
                            <img src={image.image} alt={image.alt} onClick={() => abrirFoto(image.image, image.alt)} />
                        </div>
                    );
                })}
            </div>

            <div><PhotoModal image={image} alt={description} imagestyle={imageStyle} imagesArray={imagesArray} cerrarFoto={cerrarFoto} moveForward={moveForward} moveBackward={moveBackward}></PhotoModal></div>
        </React.Fragment>
    );
};
export default Gallery;