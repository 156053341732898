import React from "react";
import { connect } from 'react-redux';

import Gallery from "../../../shared/Gallery/Gallery";
import './WorkSingle.css';

const WorkSingle = props => {
    if (props.lang === 'es') {
        return (
            <div className="info">
                <h1>{props.data.title}</h1>

                <div className="descargadossier">
                    <a href={props.data.dossier} download>Descargar Dossier Completo</a>
                </div>

                <div dangerouslySetInnerHTML={{ __html: props.data.description, }}></div>
                <Gallery images={props.data.images}></Gallery>
            </div>
        );
    }
    if (props.lang === 'en') {
        return (
            <div className="info">
                <h1>{props.data.title}</h1>

                <div className="descargadossier">
                    <a href={props.data.dossier} download>Download Full Dossier</a>
                </div>

                <div dangerouslySetInnerHTML={{ __html: props.data.description, }}></div>
                <Gallery images={props.data.images}></Gallery>
            </div>
        );
    }
    if (props.lang === 'fr') {
        return (
            <div className="info">
                <h1>{props.data.title}</h1>

                <div className="descargadossier">
                    <a href={props.data.dossier} download>Télécharger le dossier complet</a>
                </div>

                <div dangerouslySetInnerHTML={{ __html: props.data.description, }}></div>
                <Gallery images={props.data.images}></Gallery>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        lang: state.lang
    }
}
export default connect(mapStateToProps)(WorkSingle);
