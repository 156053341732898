import React from "react";

import './LoadMore.css';

const LoadMore = props => {
    let message = ""
    if (props.lang === 'es') {
        message = "cargar más"
    }
    if (props.lang === 'en') {
        message = "load more"
    }
    if (props.lang === 'fr') {
        message = "charger plus"
    }
    return (
        <div className="loadmore" style={{ display: props.cargarStyle }} >
            <button onClick={() => props.cargarmas()}>{message}</button>
        </div >
    );
};
export default LoadMore;