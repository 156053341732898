import React from "react";
import { connect } from 'react-redux';

import './LinkItem.css';

const LinkItem = props => {
    var type = "";

    if (props.type === 'info') {
        type = "+info"
    };
    if (props.type === 'invitacion') {
        if (props.lang === 'es') {
            type = "+ver invitación"
        }
        if (props.lang === 'en') {
            type = "+see invitation"
        }
        if (props.lang === 'fr') {
            type = "+voir l'invitation"
        }
    };
    if (props.type === 'prensa') {
        if (props.lang === 'es') {
            type = "+leer noticia"
        }
        if (props.lang === 'en') {
            type = "+read new"
        }
        if (props.lang === 'fr') {
            type = "+lire le nouvelle"
        }
    };
    if (props.type === 'radio') {
        if (props.lang === 'es') {
            type = "+escuchar programa"
        }
        if (props.lang === 'en') {
            type = "+listen podcast"
        }
        if (props.lang === 'fr') {
            type = "+écouter le podcast"
        }
    };
    if (props.type === 'tv') {
        if (props.lang === 'es') {
            type = "+ver programa"
        }
        if (props.lang === 'en') {
            type = "+watch program"
        }
        if (props.lang === 'fr') {
            type = "+voir programme"
        }
    };
    if (props.type === 'youtube') {
        if (props.lang === 'es') {
            type = "+ver video"
        }
        if (props.lang === 'en') {
            type = "+watch video"
        }
        if (props.lang === 'fr') {
            type = "+voir vidéo"
        }
    };


    return (
        <a href={props.link} target="blank"> {type}</a>
    );
};

const mapStateToProps = state => {
    return {
        lang: state.lang
    }
}
export default connect(mapStateToProps)(LinkItem);

