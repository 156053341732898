import React from "react";
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import './Menu.css';

const Menu = props => {

    function openMenu(e) {
        e.preventDefault();
        var x = document.getElementById("menu_responsive");
        if (x.style.display === "block") {
            x.style.display = "none";
        } else {
            x.style.display = "block";
        }
    };

    function changeToSpanish() {
        props.onChangeToSpanish();
        localStorage.setItem("lang", "es");
    };
    function changeToEnglish() {
        props.onChangeToEnglish();
        localStorage.setItem("lang", "en");
    };
    function changeToFrench() {
        props.onChangeToFrench();
        localStorage.setItem("lang", "fr");
    };

    if (props.lang === 'es') {
        return (
            <div>
                <nav className="menu">
                    <NavLink className="logo" to="/"><img src="/assets/logo.png" alt="Logo de Rocaviva"></img></NavLink>
                    <div className="menu_burger" onClick={openMenu}>|||</div>
                    <div className="lang">
                        <p onClick={changeToSpanish}>ES</p>
                        <p onClick={changeToEnglish}>EN</p>
                        <p onClick={changeToFrench}>FR</p>
                    </div>

                    <div className="rrss">
                        <a target="blank" href="https://www.facebook.com/RocavivaEventos/">
                            <img src="/assets/icons/facebook.png" alt="facebook" />
                        </a>

                        <a target="blank" href="https://www.instagram.com/rocavivaeventos/">
                            <img src="/assets/icons/instagram.png" alt="instagram" />
                        </a>

                        <a target="blank" href="https://twitter.com/rocaviva_">
                            <img src="/assets/icons/twitter.png" alt="twitter" />
                        </a>

                        <a target="blank" href="https://www.youtube.com/@rocavivaeventos">
                            <img src="/assets/icons/youtube.png" alt="youtube" />
                        </a>
                    </div>

                    <ul>
                        <li>
                            <NavLink activeClassName="selected" to="/proyectos" exact>Proyectos</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/actividades" exact>Actividades</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/comunicacion" exact>Comunicación</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/quienes" exact>Quienes Somos</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/campaña" exact>Campaña</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/libros" exact>Libros</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/colaboradores" exact>Colaboradores</NavLink>
                        </li>
                    </ul>
                </nav>

                <nav className="menu_responsive" id="menu_responsive">
                    <ul>
                        <li>
                            <NavLink activeClassName="selected" to="/proyectos" exact>Proyectos</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/actividades" exact>Actividades</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/comunicacion" exact>Comunicación</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/quienes" exact>Quienes Somos</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/campaña" exact>Campaña</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/libros" exact>Libros</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/colaboradores" exact>Colaboradores</NavLink>
                        </li>

                        <div className="lang_responsive">
                            <p onClick={changeToSpanish}>ES</p>
                            <p onClick={changeToEnglish}>EN</p>
                            <p onClick={changeToFrench}>FR</p>
                        </div>

                        <div className="rrss_responsive">
                            <a target="blank" href="https://www.facebook.com/RocavivaEventos/">
                                <img src="/assets/icons/facebook.png" alt="facebook" />
                            </a>

                            <a target="blank" href="https://www.instagram.com/rocavivaeventos/">
                                <img src="/assets/icons/instagram.png" alt="instagram" />
                            </a>

                            <a target="blank" href="https://twitter.com/rocaviva">
                                <img src="/assets/icons/twitter.png" alt="twitter" />
                            </a>

                            <a target="blank" href="https://www.youtube.com/@rocavivaeventos">
                                <img src="/assets/icons/youtube.png" alt="youtube" />
                            </a>
                        </div>
                    </ul>
                </nav>
            </div>
        );
    }
    if (props.lang === 'en') {
        return (
            <div>
                <nav className="menu">
                    <NavLink className="logo" to="/"><img src="/assets/logo.png" alt="Logo de Rocaviva"></img></NavLink>
                    <div className="menu_burger" onClick={openMenu}>|||</div>
                    <div className="lang">
                        <p onClick={changeToSpanish}>ES</p>
                        <p onClick={changeToEnglish}>EN</p>
                        <p onClick={changeToFrench}>FR</p>
                    </div>

                    <div className="rrss">
                        <a href="https://www.facebook.com/RocavivaEventos/">
                            <img src="/assets/icons/facebook.png" alt="facebook" />
                        </a>

                        <a href="https://www.instagram.com/rocavivaeventos/">
                            <img src="/assets/icons/instagram.png" alt="instagram" />
                        </a>

                        <a href="https://twitter.com/rocaviva">
                            <img src="/assets/icons/twitter.png" alt="twitter" />
                        </a>

                        <a target="blank" href="https://www.youtube.com/@rocavivaeventos">
                            <img src="/assets/icons/youtube.png" alt="youtube" />
                        </a>
                    </div>

                    <ul>
                        <li>
                            <NavLink activeClassName="selected" to="/proyectos" exact>Projects</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/actividades" exact>Activities</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/comunicacion" exact>Press</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/quienes" exact>About</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/campaña" exact>Campaign</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/libros" exact>Books</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/colaboradores" exact>Partners</NavLink>
                        </li>
                    </ul>
                </nav>

                <nav className="menu_responsive" id="menu_responsive">
                    <ul>
                        <li>
                            <NavLink activeClassName="selected" to="/proyectos" exact>Projects</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/actividades" exact>Activities</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/comunicacion" exact>Press</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/quienes" exact>About</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/campaña" exact>Campaign</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/libros" exact>Books</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/colaboradores" exact>Partners</NavLink>
                        </li>

                        <div className="lang_responsive">
                            <p onClick={changeToSpanish}>ES</p>
                            <p onClick={changeToEnglish}>EN</p>
                            <p onClick={changeToFrench}>FR</p>
                        </div>

                        <div className="rrss_responsive">
                            <a href="https://www.facebook.com/RocavivaEventos/">
                                <img src="/assets/icons/facebook.png" alt="facebook" />
                            </a>

                            <a href="https://www.instagram.com/rocavivaeventos/">
                                <img src="/assets/icons/instagram.png" alt="instagram" />
                            </a>

                            <a href="https://twitter.com/rocaviva">
                                <img src="/assets/icons/twitter.png" alt="twitter" />
                            </a>

                            <a target="blank" href="https://www.youtube.com/@rocavivaeventos">
                                <img src="/assets/icons/youtube.png" alt="youtube" />
                            </a>
                        </div>
                    </ul>
                </nav>
            </div>
        );
    }
    if (props.lang === 'fr') {
        return (
            <div>
                <nav className="menu">
                    <NavLink className="logo" to="/"><img src="/assets/logo.png" alt="Logo de Rocaviva"></img></NavLink>
                    <div className="menu_burger" onClick={openMenu}>|||</div>
                    <div className="lang">
                        <p onClick={changeToSpanish}>ES</p>
                        <p onClick={changeToEnglish}>EN</p>
                        <p onClick={changeToFrench}>FR</p>
                    </div>

                    <div className="rrss">
                        <a href="https://www.facebook.com/RocavivaEventos/">
                            <img src="/assets/icons/facebook.png" alt="facebook" />
                        </a>

                        <a href="https://www.instagram.com/rocavivaeventos/">
                            <img src="/assets/icons/instagram.png" alt="instagram" />
                        </a>

                        <a href="https://twitter.com/rocaviva">
                            <img src="/assets/icons/twitter.png" alt="twitter" />
                        </a>

                        <a target="blank" href="https://www.youtube.com/@rocavivaeventos">
                            <img src="/assets/icons/youtube.png" alt="youtube" />
                        </a>
                    </div>

                    <ul>
                        <li>
                            <NavLink activeClassName="selected" to="/proyectos" exact>Projets</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/actividades" exact>Activités</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/comunicacion" exact>Communication</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/quienes" exact>À propos de nous</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/campaña" exact>Campagne</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/libros" exact>Livres</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/colaboradores" exact>Collaborateurs</NavLink>
                        </li>
                    </ul>
                </nav>

                <nav className="menu_responsive" id="menu_responsive">
                    <ul>
                        <li>
                            <NavLink activeClassName="selected" to="/proyectos" exact>Projets</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/actividades" exact>Activités</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/comunicacion" exact>Communication</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/quienes" exact>À propos de nous</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/campaña" exact>Campagne</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/libros" exact>Livres</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="selected" to="/colaboradores" exact>Collaborateurs</NavLink>
                        </li>

                        <div className="lang_responsive">
                            <p onClick={changeToSpanish}>ES</p>
                            <p onClick={changeToEnglish}>EN</p>
                            <p onClick={changeToFrench}>FR</p>
                        </div>

                        <div className="rrss_responsive">
                            <a target="blank" href="https://www.facebook.com/RocavivaEventos/">
                                <img src="/assets/icons/facebook.png" alt="facebook" />
                            </a>

                            <a target="blank" href="https://www.instagram.com/rocavivaeventos/">
                                <img src="/assets/icons/instagram.png" alt="instagram" />
                            </a>

                            <a target="blank" href="https://twitter.com/rocaviva">
                                <img src="/assets/icons/twitter.png" alt="twitter" />
                            </a>

                            <a target="blank" href="https://www.youtube.com/@rocavivaeventos">
                                <img src="/assets/icons/youtube.png" alt="youtube" />
                            </a>
                        </div>
                    </ul>
                </nav>
            </div>
        );
    }


};

const mapStateToProps = state => {
    return {
        lang: state.lang
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onChangeToSpanish: () => dispatch({ type: 'CHANGE_TO_ES' }),
        onChangeToEnglish: () => dispatch({ type: 'CHANGE_TO_EN' }),
        onChangeToFrench: () => dispatch({ type: 'CHANGE_TO_FR' })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);