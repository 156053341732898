import React from "react";
import { connect } from 'react-redux';

import WorkList from './components/WorkList/WorkList';
import WorksContent from '../content/Works.json';

const Work = props => {
    if (props.lang === 'es') {
        return (
            <WorkList data={WorksContent.works_es} />
        );
    }
    if (props.lang === 'en') {
        return (
            <WorkList data={WorksContent.works_en} />
        );
    }
    if (props.lang === 'fr') {
        return (
            <WorkList data={WorksContent.works_fr} />
        );
    }
};

const mapStateToProps = state => {
    return {
        lang: state.lang
    }
}
export default connect(mapStateToProps)(Work);