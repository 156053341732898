import React from "react";

import './AboutItem.css';

class AboutItem extends React.Component {

    state = {
        isActive: false
    };

    render() {
        return (
            <div className="aboutitem">
                <img src={this.props.image} alt={this.props.title} />
                <h2>{this.props.name}</h2>
                <button onClick={() => this.setState({ isActive: !this.state.isActive })}>+Más</button>
                <div className={this.state.isActive ? "cv cv_open" : "cv"} dangerouslySetInnerHTML={{ __html: this.props.cv }}></div>
            </div>
        );
    }
};
export default AboutItem;