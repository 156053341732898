import React, { useState } from "react";
import { connect } from 'react-redux';

import ActivitiesList from './components/ActivitiesList/ActivitiesList';
import ActivitiesContent from '../content/Activities.json';
import LoadMore from '../shared/LoadMore/LoadMore';

let ActivitiesFinal_es = ActivitiesContent.activities_es.sort(function (a, b) {
    var dateA = new Date(b.formated_date), dateB = new Date(a.formated_date);
    return dateA - dateB;
});
let ActivitiesFinal_en = ActivitiesContent.activities_en.sort(function (a, b) {
    var dateA = new Date(b.formated_date), dateB = new Date(a.formated_date);
    return dateA - dateB;
});
let ActivitiesFinal_fr = ActivitiesContent.activities_fr.sort(function (a, b) {
    var dateA = new Date(b.formated_date), dateB = new Date(a.formated_date);
    return dateA - dateB;
});

const Activities = props => {
    const [number, setNumber] = useState(12);
    const datos_es = ActivitiesFinal_es.slice(0, number);
    const datos_en = ActivitiesFinal_en.slice(0, number);
    const datos_fr = ActivitiesFinal_fr.slice(0, number);

    const [cargarStyle, setCargarStyle] = useState("block");
    const [controlStyle, setControlStyle] = useState("a");

    if (ActivitiesFinal_es.length <= number && controlStyle === "a") {
        setCargarStyle("none");
        setControlStyle("b");
    }
    function cargarmas() {
        setNumber(number + 12)
    };

    if (props.lang === 'es') {
        return (
            <div>
                <ActivitiesList data={datos_es} lang="es" />
                <LoadMore lang="es" cargarmas={cargarmas} cargarStyle={cargarStyle} />
            </div>
        );
    }
    if (props.lang === 'en') {
        return (
            <div>
                <ActivitiesList data={datos_en} lang="en" />
                <LoadMore lang="en" cargarmas={cargarmas} cargarStyle={cargarStyle} />
            </div>
        );
    }
    if (props.lang === 'fr') {
        return (
            <div>
                <ActivitiesList data={datos_fr} lang="fr" />
                <LoadMore lang="fr" cargarmas={cargarmas} cargarStyle={cargarStyle} />
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        lang: state.lang
    }
}
export default connect(mapStateToProps)(Activities);