import React from "react";

import WorkItem from '../WorkItem/WorkItem';
import './WorkList.css';

const WorkList = props => {
    return (
        <div className="worklist">
            {props.data.map(data => {
                return <WorkItem
                    key={data.id}
                    id={data.id}
                    slug={data.slug}
                    title={data.title}
                    image={data.image_portada}
                />
            })}
        </div>
    );
};
export default WorkList;