import React from "react";
import { connect } from 'react-redux';

import AboutList from './components/AboutList/AboutList';
import AboutContent from '../content/About.json';

const About = props => {
    if (props.lang === 'es') {
        return (
            <AboutList data={AboutContent.about_es} />
        );
    }
    if (props.lang === 'en') {
        return (
            <AboutList data={AboutContent.about_en} />
        );
    }
    if (props.lang === 'fr') {
        return (
            <AboutList data={AboutContent.about_fr} />
        );
    }
};

const mapStateToProps = state => {
    return {
        lang: state.lang
    }
}
export default connect(mapStateToProps)(About);