const initiaState = {
    lang: 'es'
}

// Reducer
const reducer = (state = initiaState, action) => {
    switch (action.type) {
        case 'CHANGE_TO_ES':
            return {
                lang: 'es'
            }
        case 'CHANGE_TO_EN':
            return {
                lang: 'en'
            }
        case 'CHANGE_TO_FR':
            return {
                lang: 'fr'
            }
        default:
            return state;
    }
};

export default reducer;