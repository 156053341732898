import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import './CardModal.css';
import LinkItem from "../LinkItem/LinkItem";

const CardModal = props => {

    let itemStyle = props.itemStyle;

    if (props.lang === 'es') {
        return (
            <div className="cardmodal" style={{ display: itemStyle }} >
                <div className="cardm">
                    <div className="cardm_close">
                        <Link to="/actividades" onClick={() => props.closeItem()}>
                            <span class="material-icons">close</span>
                        </Link>
                    </div>
                    <img src={props.image} alt={props.title}></img>
                    <div className="cardm_texto">
                        <h2>{props.title}</h2>
                        <div className="cardm_resumen">
                            <div className="cardm_resumen_date">
                                <p>{props.date.day} de {props.date.month} de {props.date.year}</p>
                                <p>{props.place}</p>
                                <p>{props.time}</p>
                            </div>
                            <p>{props.description}</p>
                        </div>
                        <div className="cardm_enlaces">
                            {props.links.map(links => {
                                return <LinkItem
                                    type={links.type}
                                    link={links.link}
                                />
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    if (props.lang === 'en') {
        return (
            <div className="cardmodal" style={{ display: itemStyle }} >
                <div className="cardm">
                    <div className="cardm_close">
                        <Link to="/actividades" onClick={() => props.closeItem()}>
                            <span class="material-icons">close</span>
                        </Link>
                    </div>
                    <img src={props.image} alt={props.title}></img>
                    <div className="cardm_texto">
                        <h2>{props.title}</h2>
                        <div className="cardm_resumen">
                            <div className="cardm_resumen_date">
                                <p>{props.date.month} {props.date.day}, {props.date.year}</p>
                                <p>{props.place}</p>
                                <p>{props.time}</p>
                            </div>
                            <p>{props.description}</p>
                        </div>
                        <div className="cardm_enlaces">
                            {props.links.map(links => {
                                return <LinkItem
                                    type={links.type}
                                    link={links.link}
                                />
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    if (props.lang === 'fr') {
        return (
            <div className="cardmodal" style={{ display: itemStyle }} >
                <div className="cardm">
                    <div className="cardm_close">
                        <Link to="/actividades" onClick={() => props.closeItem()}>
                            <span class="material-icons">close</span>
                        </Link>
                    </div>
                    <img src={props.image} alt={props.title}></img>
                    <div className="cardm_texto">
                        <h2>{props.title}</h2>
                        <div className="cardm_resumen">
                            <div className="cardm_resumen_date">
                                <p>{props.date.day} {props.date.month} {props.date.year}</p>
                                <p>{props.place}</p>
                                <p>{props.time}</p>
                            </div>
                            <p>{props.description}</p>
                        </div>
                        <div className="cardm_enlaces">
                            {props.links.map(links => {
                                return <LinkItem
                                    type={links.type}
                                    link={links.link}
                                />
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        lang: state.lang
    }
}
export default connect(mapStateToProps)(CardModal);