import React from "react";
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import './Roaming.css';
import RoamingMenu2 from "./components/RoamingMenu2/RoamingMenu2";
import RoamingSingle from './components/RoamingSingle/RoamingSingle';
import WorksContent from '../../../content/Works.json';

const Roaming = props => {

    const workSlug = useParams();
    const filteredWork_es = WorksContent.works_es.filter(work => work.slug === workSlug.workSlug);
    const filteredWork_en = WorksContent.works_en.filter(work => work.slug === workSlug.workSlug);
    const filteredWork_fr = WorksContent.works_fr.filter(work => work.slug === workSlug.workSlug);

    if (props.lang === 'es') {
        return (
            <div className="roaming">
                <div className="content">
                    <RoamingMenu2 data={filteredWork_es[0]} />
                    <RoamingSingle data={filteredWork_es[0]} />
                </div>
            </div>
        );
    }
    if (props.lang === 'en') {
        return (
            <div className="roaming">
                <div className="content">
                    <RoamingMenu2 data={filteredWork_en[0]} />
                    <RoamingSingle data={filteredWork_en[0]} />
                </div>
            </div>
        );
    }
    if (props.lang === 'fr') {
        return (
            <div className="roaming">
                <div className="content">
                    <RoamingMenu2 data={filteredWork_fr[0]} />
                    <RoamingSingle data={filteredWork_fr[0]} />
                </div>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        lang: state.lang
    }
}
export default connect(mapStateToProps)(Roaming);