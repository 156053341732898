import React from "react";
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import './Single.css';
import RoamingMenu from "./components/RoamingMenu/RoamingMenu";
import WorkSingle from "./components/WorkSingle/WorkSingle";
import WorksContent from '../content/Works.json';

const Single = props => {

    const workSlug = useParams().workSlug;
    const filteredWork_es = WorksContent.works_es.filter(work => work.slug === workSlug);
    const filteredWork_en = WorksContent.works_en.filter(work => work.slug === workSlug);
    const filteredWork_fr = WorksContent.works_fr.filter(work => work.slug === workSlug);

    if (props.lang === 'es') {
        return (
            <div className="single">
                <div className="content">
                    {
                        filteredWork_es[0].roaming.length > 0 ?
                            <RoamingMenu data={filteredWork_es[0]} />
                            : <div></div>
                    }
                    <WorkSingle data={filteredWork_es[0]} />
                </div>
            </div>
        );
    }
    if (props.lang === 'en') {
        return (
            <div className="single">
                <div className="content">
                    {
                        filteredWork_en[0].roaming.length > 0 ?
                            <RoamingMenu data={filteredWork_en[0]} />
                            : <div></div>
                    }
                    <WorkSingle data={filteredWork_en[0]} />
                </div>
            </div>
        );
    }
    if (props.lang === 'fr') {
        return (
            <div className="single">
                <div className="content">
                    {
                        filteredWork_fr[0].roaming.length > 0 ?
                            <RoamingMenu data={filteredWork_fr[0]} />
                            : <div></div>
                    }
                    <WorkSingle data={filteredWork_fr[0]} />
                </div>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        lang: state.lang
    }
}
export default connect(mapStateToProps)(Single);