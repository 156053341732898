import React from "react";

import MainCard from './components/MainCard/MainCard';
import './MainNews.css';


const MainNews = props => {
    return (
        <div className="mainnews">
            {props.data.map(data => {
                return (
                    <MainCard
                        key={data.id}
                        title={data.title}
                        place={data.place}
                        start_date={data.start_date}
                        end_date={data.end_date}
                        image={data.image}
                        extra={data.extra}
                        url={data.url}
                    />
                );
            })}
        </div>
    );
};
export default MainNews;