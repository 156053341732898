import React from "react";
import { connect } from 'react-redux';

import './Card.css';
import LinkItem from "./components/LinkItem/LinkItem";

const Card = props => {
    if (props.lang === 'es') {
        return (
            <div className="card">
                <img src={props.image} alt={props.title}></img>
                <div className="card_texto">
                    <h2>{props.title}</h2>
                    <div className="card_resumen">
                        <div className="card_resumen_date">
                            <p>{props.date.day} de {props.date.month} de {props.date.year}</p>
                            <p>{props.place}</p>
                            <p>{props.time}</p>
                        </div>
                        <p>{props.description}</p>
                    </div>
                    <div className="card_enlaces">
                        {props.links.map(links => {
                            return <LinkItem
                                type={links.type}
                                link={links.link}
                            />
                        })}
                    </div>
                </div>
            </div>
        );
    }
    if (props.lang === 'en') {
        return (
            <div className="card">
                <img src={props.image} alt={props.title}></img>
                <div className="card_texto">
                    <h2>{props.title}</h2>
                    <div className="card_resumen">
                        <div className="card_resumen_date">
                            <p>{props.date.month} {props.date.day}, {props.date.year}</p>
                            <p>{props.place}</p>
                            <p>{props.time}</p>
                        </div>
                        <p>{props.description}</p>
                    </div>
                    <div className="card_enlaces">
                        {props.links.map(links => {
                            return <LinkItem
                                type={links.type}
                                link={links.link}
                            />
                        })}
                    </div>
                </div>
            </div>
        );
    }
    if (props.lang === 'fr') {
        return (
            <div className="card">
                <img src={props.image} alt={props.title}></img>
                <div className="card_texto">
                    <h2>{props.title}</h2>
                    <div className="card_resumen">
                        <div className="card_resumen_date">
                            <p>{props.date.day} {props.date.month} {props.date.year}</p>
                            <p>{props.place}</p>
                            <p>{props.time}</p>
                        </div>
                        <p>{props.description}</p>
                    </div>
                    <div className="card_enlaces">
                        {props.links.map(links => {
                            return <LinkItem
                                type={links.type}
                                link={links.link}
                            />
                        })}
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        lang: state.lang
    }
}
export default connect(mapStateToProps)(Card);