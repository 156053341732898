import React from "react";
import { connect } from 'react-redux';

import './MainCard.css';


const MainCard = props => {
    if (props.lang === 'es') {
        return (
            <div className="maincard">
                <div className="imagen">
                    <img src={props.image} alt={props.title} />
                </div>

                <div className="data">
                    <div className="data_text">
                        <h2>{props.title}</h2>
                        <p>{props.place}</p>
                        <p>Inicio: {props.start_date.day} de {props.start_date.month} de {props.start_date.year}</p>
                        <p>Fin: {props.end_date.day} de {props.end_date.month} de {props.end_date.year}</p>
                        {props.extra && <p className="extra">{props.extra}</p>}
                    </div>
                    <a href={props.url}><span>+INFORMACIÓN</span></a>
                </div>
            </div>
        );
    }
    if (props.lang === 'en') {
        return (
            <div className="maincard">
                <div className="imagen">
                    <img src={props.image} alt={props.title} />
                </div>

                <div className="data">
                    <div className="data_text">
                        <h2>{props.title}</h2>
                        <p>{props.place}</p>
                        <p>Start: {props.start_date.month} {props.start_date.day} , {props.start_date.year}</p>
                        <p>End: {props.end_date.day} {props.end_date.month} , {props.end_date.year}</p>
                    </div>
                    <a href={props.url}><span>+INFO</span></a>
                </div>
            </div>
        );
    }
    if (props.lang === 'fr') {
        return (
            <div className="maincard">
                <div className="imagen">
                    <img src={props.image} alt={props.title} />
                </div>

                <div className="data">
                    <div className="data_text">
                        <h2>{props.title}</h2>
                        <p>{props.place}</p>
                        <p>Début: {props.start_date.month} {props.start_date.day} {props.start_date.year}</p>
                        <p>Finir: {props.end_date.month} {props.end_date.day} {props.end_date.year}</p>
                    </div>
                    <a href={props.url}><span>+INFO</span></a>
                </div>
            </div>
        );
    }
};
const mapStateToProps = state => {
    return {
        lang: state.lang
    }
}
export default connect(mapStateToProps)(MainCard);